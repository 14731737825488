import { LOAD_MODULE, LOAD_ROLE, LOAD_USER, LOAD_PROJECT, LOAD_CUSTOMER, LOAD_WORK, LOAD_ITEM, LOAD_DELIVERY, LOAD_EXPENSE, LOAD_INVOICE, LOAD_QUOTATION, LOAD_QUOTATIONDETAIL } from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,
  project: null,
  customer: null,
  work: null,
  item: null,
  delivery: null,
  expense: null,
  invoice: null,
  quotation: null,
  quotationDetail: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        user: payload.data,
      };
    case LOAD_ROLE:
      return {
        ...state,
        role: payload.data,
      };
    case LOAD_MODULE:
      return {
        ...state,
        module: payload.data,
      };
    case LOAD_PROJECT:
      return {
        ...state,
        project: payload.data,
      };
    case LOAD_QUOTATION:
      return {
        ...state,
        quotation: payload.data,
      };
    case LOAD_QUOTATIONDETAIL:
      return {
        ...state,
        quotationDetail: payload.data,
      };
    case LOAD_CUSTOMER:
      return {
        ...state,
        customer: payload.data,
      };
    case LOAD_WORK:
      return {
        ...state,
        work: payload.data,
      };
    case LOAD_ITEM:
      return {
        ...state,
        item: payload.data,
      };
    case LOAD_DELIVERY:
      return {
        ...state,
        delivery: payload.data,
      };
    case LOAD_EXPENSE:
      return {
        ...state,
        expense: payload.data,
      };
    case LOAD_INVOICE:
      return {
        ...state,
        invoice: payload.data,
      };
    default:
      return state;
  }
}
