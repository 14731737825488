// alert.js
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth.js
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";

// data.js
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const CRUD_FAIL = "CRUD_FAIL";

// master.js
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_MODULE = "LOAD_MODULE";
export const LOAD_PROJECT = "LOAD_PROJECT";
export const LOAD_QUOTATION = "LOAD_QUOTATION";
export const LOAD_QUOTATIONDETAIL = "LOAD_QUOTATIONDETAIL";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_WORK = "LOAD_WORK";
export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_DELIVERY = "LOAD_DELIVERY";
export const LOAD_EXPENSE = "LOAD_EXPENSE";
export const LOAD_INVOICE = "LOAD_INVOICE";


